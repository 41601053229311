
const stripeConfiguration = {
    data:[{
        key:1,
        name:"1-20 Emails",
        link:"https://buy.stripe.com/aEU29G7Yjdl03UA4gg"
    },
    {
        key:2,
        name:"21-50 Emails",
        link:"https://buy.stripe.com/eVa01ybav5SyfDi6op"
    },
    {
        key:3,
        name:"51+ Emails",
        link:"https://buy.stripe.com/00gdSo0vRft81Ms146"
    }]
}

export default stripeConfiguration