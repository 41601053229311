import './Text.css'

const TextBoxx = () => {


    return (


        <div className="textBoxxStyle">
        <h2>
            Welcome to HAN IT Solutions!
        </h2>
        <h4>
        Here we provide automated bookkeeping for your favorite Retailer sites including the following
        </h4>
        <ul style={{listStyle: "none",width:"100%",display:"flex",flexDirection:'column',alignContent:"center",justifyContent: "center"}}>
            <div style={{width:"50%",alignItems:"center",flexDirection:"column",alignSelf:"center",fontFamily:"Garamond",fontSize:"100%"}}>
        <li>
            Stripe
        </li>
        <li>
            Shopify
        </li>
        <li>
            Walmart
        </li>
        <li>
            Bestbuy
        </li>
        <li>
            Target
        </li>
        <li>
        </li>
        </div>
        </ul>
        </div>
    )
}

export default TextBoxx;
