import './Terms.css'
import Wrapper from '../wrapper/Wrapper'
import { useState, useEffect } from 'react'
import { Document, Page,pdfjs } from 'react-pdf/dist/esm/entry.webpack5'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/'
}

const Terms = props => {
  const [docs, setDocState] = useState(null)
  const [numPages, setNumPages] = useState(null)


  function onDocumentLoadSuccess ({ numPages: nextNumPages }) {
    setNumPages(nextNumPages)
  }

  // useEffect(() => { pdfjs.GlobalWorkerOptions.workerSrc =`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;});
  useEffect(() => {
    if (props.HTMLViewer) {
      setDocState(prevState => {
        return props.HTMLViewer
      })
    }
  }, [props.HTMLViewer])

  return (
    <Wrapper>
      <div className='serviceContainer'>
        <div className='documentViewer'>
          <Document
            file={docs}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                size='A4'
                style={{ alignSelf: 'center', textAlign: 'center',paddingBottom:"80pt" }}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ))}
          </Document>
        </div>
      </div>
    </Wrapper>
  )
}

export default Terms
