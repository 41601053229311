import "./Header.css"



const Header = () => {
return (
<div className="headerStyle">
    <h2> HAN IT Solutions</h2>
</div>
)}

export default Header;