import './Wrapper.css'
import Buttons from '../buttons/Buttons'
import Header from '../../header/Header'

const Wrapper = props => {
  return (
    <div className='wrapper'>
      <div style={{ height: '15%', width: '100%',paddingBlock:"1%",backgroundColor:"rgba(0, 0, 0, 0.12)"}}>
        <Header />
        <Buttons />
      </div>
      <div className='childrenContainer'>{props.children}</div>
    </div>
  )
}

export default Wrapper
