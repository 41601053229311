import './Home.css'
import Wrapper from '../wrapper/Wrapper'
import TextBoxx from './Text'
import StripeDiscord from './StripeDiscord'

const Home = () => {
  return (
    <Wrapper>
      <div className='HomeContainer'>
        <div
          style={{
            marginTop:"2%",
            width: '80%',
            alignContent:'center',
            alignItems:'center',
            justifyContent:'space-between',
            justifyItems:'center'

          }}
        >
            <div>
        <TextBoxx />
        </div>
        <div className="stripeFormatting">
        <StripeDiscord/>
        </div>
      </div>
      </div>

    </Wrapper>
  )
}

export default Home

//https://discord.gg/PUKSjw9SkJ
