import "./StripeOption.css"
import stripeConfiguration from "./config";
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'


const StripeOptions = () => {

    return (
        <ButtonToolbar>
          <ButtonGroup>
            <div className="button-divider">
            {
                stripeConfiguration.data.map(element => {
                    return (
                        <button
                        className="button-style"
                        onClick={(e)=> {
                                e.preventDefault();
                                window.open(element.link,'_blank',"noreferrer")
                        }}>
                            {element.name}
                        </button>
                    )
                })
            }
            </div>
          </ButtonGroup>
      </ButtonToolbar>

    );





}

export default StripeOptions;
