import "./StripeDiscord.css"
import "./Home.css"
import StripeOptions from "./StripeOptions/StripeOptions";



const DiscordLink = "https://discord.gg/uC66Mx8zVw"

const StripeDiscord = () => {

    return (

        <div className="textBoxxStyle">
            <button
            className="button-style"
            
            onClick={(e)=> {
                e.preventDefault();
                window.open(DiscordLink,'_blank','noreferrer')
            }}>

                Join Our Discord Link Here
            </button>

            <h2>
                Visit our Stripe Payment Options after order confirmation in the Discord
            </h2>
            <StripeOptions/>


        </div>



    )





}

export default StripeDiscord;