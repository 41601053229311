import './App.css'
import Home from './pages/home/Home.js'
import Terms from './pages/terms/Terms.js'
import React from 'react'
import { Routes, Route } from 'react-router-dom'
function App () {
  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/tos' element={<Terms HTMLViewer='TOS.pdf' />} />
        <Route path='/privacy-policy' element={<Terms HTMLViewer='PP.pdf' />} />
        <Route path='/cookie-policy' element={<Terms HTMLViewer='CP.pdf' />} />
        <Route path='*' element={<Home/>} />
      </Routes>
    </div>
  )
}

export default App
