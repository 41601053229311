import './Buttons.css'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import { Outlet, Link } from 'react-router-dom'

const Buttons = props => {
  return (
    <ButtonToolbar>
      <nav>
        <ButtonGroup onClick={props.ButtonFunction}>
          <Link to='/home'>
            <Button className='button-style' id='Home'>
              Home
            </Button>{' '}
          </Link>
          <Link to='/tos'>
            <Button className='button-style' id='TOS'>
              Terms of Service
            </Button>{' '}
          </Link>
          <Link to='/privacy-policy'>
            <Button className='button-style' id='PP'>
              Privacy Policy
            </Button>{' '}
          </Link>
          <Link to='/cookie-policy'>
            <Button className='button-style' id='CP'>
              Cookie Policy
            </Button>{' '}
          </Link>
        </ButtonGroup>
      </nav>
      <Outlet />
    </ButtonToolbar>
    // </div>
  )
}

export default Buttons
